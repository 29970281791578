<template>
<div class="bg">
    <div class="centered">
        <div class="centered-element">
            <div class="header">
                <h1>HAYOKOI<br>アカウントの作成が完了しました</h1>
                <p>HAYOKOIのご利用ありがとうございます</p>
                <p>
                    以下のリンクからアプリをダウンロード後、ログインするとご利用を開始できます。
                </p>
            </div>
            <div class="Sample">
                <h2>iOSをお使いの方</h2>
                  <p><a href="https://apps.apple.com/jp/app/hayokoi/id6479526401"><img :src="require('/src/assets/img/QR_AppStore.png')" /></a></p>
                <h2>Androidをお使いの方</h2>  
                  <p><a href="https://play.google.com/store/apps/details?id=llc.wakatec.hayokoi&pcampaignid=web_share"><img :src="require('/src/assets/img/QR_PlayStore.png')" /></a></p>
            </div>

            <div class="nav">
                <router-link to="/Dashboard">
                    <ButtonPositive button_title='TOP'/>
                </router-link>
                
            </div>
        </div>
    </div>
</div>
</template>



<script setup>
import ButtonPositive from '../Common/button_positive.vue';
//import ButtonNegative from '../Common/button_negative.vue';
import { UserDataStore } from '/src/store/UserData';
import { onBeforeMount } from 'vue';
const UserData = UserDataStore();

onBeforeMount(() => {
    if(UserData.AlreadyRegistered){
        UserData.$reset();
    }//ここでStoreに格納していた登録データを初期化する
});

</script>

<style>

@font-face {
	font-family: 'mplus';
	src: url("/src/assets/fonts/rounded-mplus-1c-regular.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.bg {
    background-color: grey;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* ビューポートの高さに合わせる */
}

.centered-element{
    background-color: white;
}

.header {
    background-color: #007B43;
    padding: 20px;
}

.header h1{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 30px;
}

.header p{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 18px;
}

.Sample h2 {
    font-family: 'mplus';
    color: black;
    text-align: center;
    font-size: 26px;
}

.Sample img {
    width: 35%;
    padding: 10px;
}

.nav {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
}
</style>