<template>
<div v-if="is_distributor">
    <div class="bg">
        <div class="centered">
            <div class="centered-element">
                <div class="header">
                    <h1>Hayokoi販売管理</h1>
                </div>
                <div class="content">
                    <p>ようこそ、{{user_name}}さん</p>
                    <div class="Register">
                        <h2>アカウント作成</h2>
                            <router-link to="/Register">
                                <ButtonPositive button_title='登録フォーム'/>
                            </router-link>
                    </div>
                    <div class="customer">
                        <h2>顧客管理</h2>
                        <span @click=openNewTab(url1)><ButtonPositive button_title='顧客リスト'/></span>
                    </div>

                    <div class="distributor">
                        <h2>販売店登録</h2>
                        <router-link to="/SalesAccountRegister">
                        <ButtonPositive button_title='登録フォーム'/>
                        </router-link>
                    </div>

                    <div class="">
                        <h2>Hayokoi公式サイト</h2>
                        <router-link to="/">
                        <ButtonPositive button_title='公式サイトに戻る'/>
                        </router-link>
                    </div>
                    
                    <div class="logout">
                        <ButtonNegative @click=logout() button_title='ログアウト'/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div v-if="!is_distributor">
    <div class="bg">
        <div class="centered">
            <div class="centered-element">
                <div class="header">
                    <h1>Hayokoiアカウント管理</h1>
                </div>
                    <div class="content">
                        <div class="Register">
                            <h2>お支払いに関して</h2>
                            <p>以下のボタンから決済方法の変更やご利用の停止などができます。</p>
                                <ButtonPositive @click="openNewTab(url2)" button_title='お支払い管理'/>
                        </div>
                        <div class="logout">
                        <ButtonNegative @click=logout() button_title='ログアウト'/>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import {onBeforeMount,ref} from 'vue';
import ButtonPositive from '../Common/button_positive.vue';
import ButtonNegative from '../Common/button_negative.vue';
import { getAuth } from 'firebase/auth';
import { getFirestore,doc,getDoc } from 'firebase/firestore';
import router from '@/router';

const url1 = ref('https://docs.google.com/spreadsheets/d/1bi4JCSZtueyhjKjzFifSv1sVPh-q6qViPfb5msuTPX4/edit?usp=drive_link');
const url2 = ref('https://billing.stripe.com/p/login/3cs16F19LdDK3K07ss')

var is_distributor = ref(false);
var user_name = ref('');


const openNewTab = (url) => {
      // 指定したURLを新しいタブで開く
      window.open(url, '_blank');
    }

const logout = async () => {
    const auth = getAuth();
      auth.signOut().then(() => {
        // ログアウト成功時の処理
        localStorage.removeItem('idToken');
        localStorage.removeItem('uid');
        alert('ログアウトしました')
        router.push('/')
      }).catch((error) => {
        // エラーハンドリング
        alert("Logout error:", error);
      });
    }

function user_type() {
    const db = getFirestore(); // Firestoreの初期化
    const docRef = doc(db, "users", localStorage.getItem('uid'));
    getDoc(docRef).then(async (doc) => {
    if (doc.exists()) {
      // ドキュメントが存在する場合、そのデータを取得
      is_distributor.value = await doc.get('distributor');
      user_name.value = await doc.get('family_name');
    } else {
        alert('nodata');
    }
  })
  .catch((error) => {
    // エラーハンドリング
    console.error("Error getting document:", error);
  });
}

onBeforeMount(() => {
    user_type();
});
</script>

<style>

@font-face {
	font-family: 'mplus';
	src: url("/src/assets/fonts/rounded-mplus-1c-regular.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.bg {
    background-color: grey;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* ビューポートの高さに合わせる */
}

.centered-element{
    background-color: white;
}

.header {
    background-color: #007B43;
    padding: 20px;
}

.header h1{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 30px;
}

.header p{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 18px;
}

.nav {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.content {
    text-align: center;
}

.content p{
    font-family: 'mplus';
    color: black;
    text-align: center;
    font-size: 18px;
}

.content h2 {
    font-family: 'mplus';
    margin-top: 20px;
    font-size: 24px;
    padding: 15px;
}

.logout {
    margin-top: 100px;
    margin-bottom: 20px;
}
</style>