<template>
<div class="bg">
    <div class="centered">
        <div class="centered-element">
            <div class="Terms">
              <h1>特定商取引法に基づく表記</h1>
              <table border="1">
                <tr>
                  <td>販売業者の名称</td>
                  <td>合同会社WakaTec</td>
                </tr>

                <tr>
                  <td>所在地</td>
                  <td>〒869-2807<br>
                    熊本県阿蘇市大字波野新波野４６４
                  </td>
                </tr>

                <tr>
                  <td>電話番号</td>
                  <td>09094996513<br>
                    受付時間: 24時間
                  </td>
                </tr>

                <tr>
                  <td>メールアドレス</td>
                  <td>
                      wakatecllc@gmail.com
                  </td>
                </tr>

                <tr>
                  <td>運営統括責任者</td>
                  <td>
                    若井睦月
                  </td>
                </tr>

                <tr>
                  <td>追加手数料等の追加料金</td>
                  <td>
                    アプリのダウンロード: 無料<br>
                    基本機能の利用: 公式ページに表示されている金額（税込）
                  </td>
                </tr>

                <tr>
                  <td>返品・交換ポリシー</td>
                  <td>
                    <p>
                      ＜お客様都合の返品,交換の場合＞<br>
                      デジタルコンテンツの特性上、購入確定後の返品,交換には応じられません。
                    </p>
                    <p>
                      ＜商品に不備がある場合＞<br>
                      サポートセンターまでご連絡ください。<br>
                      当社が不具合の改善対応をいたします。
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    引渡時期
                  </td>
                  <td>
                    代金決済の手続完了後、直ちにご利用いただけます。
                  </td>
                </tr>

                <tr>
                  <td>
                    受付可能な決済手段
                  </td>
                  <td>
                    クレジットカード
                  </td>
                </tr>

                <tr>
                  <td>
                    決済期間
                  </td>
                  <td>
                    クレジットカード：即時決済
                  </td>
                </tr>

                <tr>
                  <td>
                    販売価格
                  </td>
                  <td>
                    各商品・サービスの価格は、Hayokoi公式ページに記載しています。
                  </td>
                </tr>

                <tr>
                  <td>
                    ソフトウェアの動作環境
                  </td>
                  <td>
                    Android 8.0以上、iOS 11.0以上
                  </td>
                </tr>
              </table>           
            </div>

            <div class="nav">
                    <ButtonPositive button_title='戻る' @click="goBack()"/>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import ButtonPositive from '../Common/button_positive.vue';
// Vue Routerをインポート
import { useRouter } from 'vue-router';


// useRouter()を使用してrouterオブジェクトを取得
const router = useRouter();

// 前のページに戻る
const goBack = () => {
  router.push('/');
};


</script>



<style scoped>


@font-face {
	font-family: 'mplus';
	src: url("/src/assets/fonts/rounded-mplus-1c-regular.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.bg {
    background-color: grey;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* ビューポートの高さに合わせる */
}

.centered-element{
    background-color: white;
}

.Terms {
  width: 95vw;
  height: 75vh;
  overflow-y: scroll;
  border: black 2px solid;
  padding: 10px;
}

.Terms h1 {
  padding: 20px;
  text-align: center;
}

.Terms p {
  margin: 10px;
  font-family: 'mplus';
}

.Terms table {
  border: grey;
}

.Terms td {
  padding: 10px;
  font-family: 'mplus';
  overflow: auto;
}

.Terms li {
  list-style: none;
  margin: 10px;
}

.header {
    background-color: #007B43;
    padding: 20px;
}

.header h1{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 30px;
}

.header p{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 18px;
}

.nav {
  width: fit-content;
  height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.nav button {
  width: 200px;
  height: 50px;
}
</style>