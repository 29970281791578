<template>
<div class="bg">
    <div class="centered">
        <div class="centered-element">
            <div class="header">
                <h1>Hayokoiマイページ</h1>
                <p>メールアドレスとパスワードを入力してください</p>
            </div>

            <div class="login_form">
                 <form @submit.prevent="login">
                    <ul>
                        <li>
                            <label for="email">メールアドレス:</label>
                        </li>
                        <li>
                            <input type="email" id="email" v-model="email" size="30" required>
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <label for="password">パスワード:</label>
                        </li>
                        <li>
                            <input type="password" id="password" v-model="password" size="15" required>
                        </li>
                    </ul>

                    <div class="LoadingSpace" v-if="isLoading">
                        <LoadingCircle/>
                    </div>
                    
                    <div class="btn">
                        <button type="submit"><ButtonPositive button_title='ログイン'/></button>
                    </div>
                </form>
            </div>

            <div class="btn">
                <ButtonNegative button_title='戻る' @click="goBack()"/>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useRouter } from 'vue-router';
import ButtonPositive from '../Common/button_positive.vue';
import ButtonNegative from '../Common/button_negative.vue';
import LoadingCircle from '../Common/loading_circle.vue';

const router = useRouter();//Routerを定義
const isLoading = ref(false);

onBeforeMount(() => {
  if(localStorage.getItem('idToken') != ''){
    GoToNextPage();
  }
});


const GoToNextPage = () => {
  //登録内容確認画面へ遷移させる関数
  router.push('/Dashboard');
};

// 前のページに戻る
const goBack = () => {
  router.back();
};

const email = ref('');
const password = ref('');
var uid = ref('');

const login = async () => {
    isLoading.value = true;
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, email.value, password.value).then(async (userCredential) => {
    // ログイン成功後にトークンを取得
    uid = userCredential.user.uid;
    userCredential.user.getIdToken();
    if(!userCredential.user.emailVerified){
        throw new Error('メールアドレスの確認が完了していません')
    }
  }).then((idToken) => {
    //トークンをローカルに保存
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("uid", uid);
    isLoading.value = false;
    GoToNextPage();
  })
  .catch((error) => {
    // エラーハンドリング
    if(error.code == 'auth/user-not-found'){
        alert('アカウントが登録されていません')
    }
    
    if(error.code == 'auth/wrong-password'){
        alert('パスワードが一致しません')
    }

    isLoading.value = false;
  });
};
</script>

<style>

@font-face {
	font-family: 'mplus';
	src: url("/src/assets/fonts/rounded-mplus-1c-regular.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.bg {
    background-color: grey;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* ビューポートの高さに合わせる */
}

.centered-element{
    background-color: white;
}

.header {
    background-color: #007B43;
    padding: 20px;
}

.header h1{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 30px;
}

.header p{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 18px;
}

.nav {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.login_form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_form input {
    margin: 10px;
    font-size: 20px;
    font-family: 'mplus';
    line-height: 1.5;
}

.login_form label {
    font-size: 18px;
    font-family: 'mplus';
}

.login_form ul {
    list-style: none;
    margin: 20px;
}

.login_form button {
    border-style: none;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
</style>