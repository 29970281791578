import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import { createPinia } from 'pinia';
import { createPersistedState } from "pinia-plugin-persistedstate"; // 追加
const pinia = createPinia();
pinia.use(createPersistedState());

//Firebase情報登録
import { initializeApp } from 'firebase/app';
import { getFirestore, addDoc, collection } from 'firebase/firestore';

//Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBstXvdkRKOs1dhDrD2yV0tNW-Qj9AVFIc",
  authDomain: "hayokoi-cf056.firebaseapp.com",
  projectId: "hayokoi-cf056",
  storageBucket: "hayokoi-cf056.appspot.com",
  messagingSenderId: "160156894678",
  appId: "1:160156894678:web:624746ce00d6944ba95077",
  measurementId: "G-YJW3BGBW3R"
  };

// Firebaseを初期化
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default {
  methods: {
    async submitForm(event) {
      event.preventDefault();

      // 全てのフィールドが正しく入力されているか確認
      let hasErrors = false;
      for (let field in this.formData) {
        if (!this.formData[field]) {
          this.errors[field] = true;
          hasErrors = true;
        }
      }

      if (hasErrors) return;

      // Firebase Firestoreにデータを保存
      try {
        await addDoc(collection(db, 'users'), this.formData);
        alert('ユーザー情報が正常に保存されました。');
      } catch (error) {
        alert('エラーが発生しました。');
        console.error("Error adding document: ", error);
      }
    },
    // 他のメソッド...
  }
};

createApp(App).use(router).use(pinia).mount('#app')
