<template>
<router-view v-slot="{ Component }">
  <transition name="slide" mode="in-place">
    <component :is="Component" :key="$route.path" />
  </transition>
</router-view>
</template>

<script setup>
</script>

<style scoped>
.fade-enter-active {
    transition: opacity 1s;
    opacity: 0;
}
.fade-enter-to {
    opacity: 1;
}

.fade-leave-active {
    transition: opacity 1s;
    opacity: 1;
}
.fade-leave-to {
    opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s,transform 0.5s;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform:translate(-30%)
}
</style>