import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/Home.vue'
import Top from '@/components/Register/RegisterTop.vue'
import TermsOfUse from '@/components/Register/TermsOfUse.vue'
import RegisterUser from '@/components/Register/RegisterUser.vue'
import Payment from '@/components/Register/Payment.vue'
import RegisterDataChk from '@/components/Register/RegisterDataChk.vue'
import RegisterCompleted from '@/components/Register/RegisterCompleted.vue'
import Kiyaku from '@/components/Terms/Kiyaku.vue'
import Privacy from '@/components/Terms/Privacy.vue'
import Tokutei from '@/components/Terms/Tokutei.vue'
import Manual from '@/components/Terms/Manual.vue'
import Login from '@/components/Account/Login.vue'
import Dashboard from '@/components/Account/Dashboard.vue'
import SalesAccountRegister from '@/components/Account/SalesAccountRegister.vue'
import DataChk from '@/components/Account/DataChk.vue'
import SARegisterCompleted from '@/components/Account/SARegisterCompleted.vue'


import { START_LOCATION } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title: '車検証QRを活用するならWakaTecのHayokoiを使ってみませんか？'
    },
  },

  {
    path: '/Register',
    name: 'Register',
    component: Top,
    meta:{
      title: 'Hayokoi会員登録フォーム-トップ',
      requiresAuth: true
    }
  },

  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta:{
      title: 'Hayokoi-ログイン'
    }
  },
  
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta:{
      title: 'Hayokoi-アカウント',
      requiresAuth: true
    }
  },


  {
    path: '/TermsOfUse',
    name: 'TermsOfUse',
    component: TermsOfUse,
    meta:{
      title: 'Hayokoi会員登録フォーム-利用規約'
    },
    beforeEnter: (to, from) => {
      if (from === START_LOCATION) {
        return '/'
      }
    }
  },

  {
    path: '/RegisterUser',
    name: 'RegisterUser',
    component: RegisterUser,
    meta:{
      title: 'Hayokoi会員登録フォーム-会員情報入力'
    },
    beforeEnter: (to, from) => {
      if (from === START_LOCATION) {
        return '/'
      }
    }
  },

  {
    path: '/RegisterDataChk',
    name: 'RegisterDataChk',
    component: RegisterDataChk,
    meta:{
      title: 'Hayokoi会員登録フォーム-登録情報確認'
    },
    beforeEnter: (to, from) => {
      if (from === START_LOCATION) {
        return '/'
      }
    }
  },

  {
    path: '/Payment',
    name: 'Payment',
    component: Payment,
    meta:{
      title: 'Hayokoi会員登録フォーム-お支払い'
    },
    beforeEnter: (to, from) => {
      if (from === START_LOCATION) {
        return '/'
      }
    }
  },

  {
    path: '/RegisterCompleted',
    name: 'RegisterCompleted',
    meta:{
      title: 'Hayokoi会員登録フォーム-登録完了'
    },
    component: RegisterCompleted,
  },

  {
    path: '/SalesAccountRegister',
    name: 'SalesAccountRegister',
    meta:{
      title: 'Hayokoi販売店登録フォーム'
    },
    component: SalesAccountRegister,
  },

  {
    path: '/DataChk',
    name: 'DataChk',
    meta:{
      title: 'Hayokoi販売店登録フォーム-入力内容確認'
    },
    component: DataChk,
  },

  {
    path: '/SARegisterCompleted',
    name: 'SARegisterCompleted',
    meta:{
      title: 'Hayokoi販売店登録フォーム-登録完了'
    },
    component: SARegisterCompleted,
  },

  {
    path: '/Kiyaku',
    name: 'Kiyaku',
    meta:{
      title: 'Hayokoi-利用規約'
    },
    component: Kiyaku,
  },

  {
    path: '/PrivacyPolicy',
    name: 'Privacy',
    meta:{
      title: 'Hayokoi-プライバシーポリシー'
    },
    component: Privacy,
  },

  {
    path: '/Tokutei',
    name: 'Tokutei',
    meta:{
      title: 'Hayokoi-特定商取引法に基づく表記'
    },
    component: Tokutei,
  },

  {
    path: '/Manual',
    name: 'Manual',
    meta:{
      title: 'Hayokoi-取扱説明書'
    },
    component: Manual,
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// ページ遷移ごとにタイトルを変更
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

router.beforeEach((to) => {
  if (to.meta.requiresAuth && localStorage.getItem("idToken") == null) {
    return { name: "Login" };
  }
});

export default router