import { defineStore } from 'pinia'

export const UserDataStore = defineStore('UserData', {
    state: () => {
      return {
      IsAgreeTerms: false,
      AlreadyRegistered:false,
      family_name: '',
      user_name: '',
      company_type:'',
      user_type:'',
      company_name: '',
      postcode: '',
      prefecture: '',
      city: '',
      town: '',
      Street: '',
      building: '',
      tel: '',
      phone: '',
      email: '',
      password: '',
      }
    },
    persist:true,//永続化設定
  },
)