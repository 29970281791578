<script setup>
import axios from 'axios';
import ButtonPositive from '../Common/button_positive.vue';
import ButtonNegative from '../Common/button_negative.vue';
import { ref,watch } from 'vue';
import { useRouter } from 'vue-router';
import { UserDataStore } from '/src/store/UserData';
import { PrefecturesStore } from '/src/store/Prefectures';

//----------------------------------初期値設定ここから----------------------------------
const UserData = UserDataStore();//storeから値取得
const router = useRouter();//Routerを定義
const Prefectures = PrefecturesStore().prefectures;
const user_name = ref(UserData.user_name);
const family_name = ref(UserData.family_name);
const company_type = ref(UserData.company_type);
const company_name = ref(UserData.company_name);
const postcode = ref(UserData.postcode);
const prefecture = ref(UserData.prefecture);
const town = ref(UserData.town);
const city = ref(UserData.city);
const Street = ref(UserData.Street);
const building = ref(UserData.building);
const tel = ref(UserData.tel);
const phone = ref(UserData.phone);
const email = ref(UserData.email);
const password1 = ref(UserData.password);
const password2 = ref(UserData.password);
const PtoA = ref('');
const validationError = new Map();
let validationMsg = ref('');
const pce = ref('');
const isLoading = ref(false);//ローディングサークル初期値
//----------------------------------初期値設定ここまで----------------------------------

const GoToNextPage = () => {
  //登録内容確認画面へ遷移させる関数
  router.push('/DataChk');
};

// 前のページに戻る
const goBack = () => {
  router.back();
};

// 変数の変更を監視し、バリデーションをトリガー
watch(postcode,() => {
  ValidatePostCode();
});

watch(tel, () => {
  VaildateTel();
});

watch(phone, () => {
  VaildatePhone();
});

watch(email, () => {
  VaildateEmail();
});

watch(password1, () => {
  VaildatePassWord();
  IsSamePassword();
});

watch(password2, () => {
  VaildatePassWord();
  IsSamePassword();
});

function EmptyCheck() {
  //空欄を確認する関数(telとbuildingは外してある)
  let EmptyCheckList = [];
  EmptyCheckList.push(family_name.value);
  EmptyCheckList.push(user_name.value);
  EmptyCheckList.push(company_type.value);
  EmptyCheckList.push(company_name.value);
  EmptyCheckList.push(postcode.value);
  EmptyCheckList.push(prefecture.value);
  EmptyCheckList.push(city.value);
  EmptyCheckList.push(town.value);
  EmptyCheckList.push(Street.value);
  EmptyCheckList.push(phone.value);
  EmptyCheckList.push(email.value);
  EmptyCheckList.push(password1.value);
  EmptyCheckList.push(password2.value);

  if(EmptyCheckList.includes('')){
    return true;//空欄あり
  }else{
    return false;//空欄なし
  } 
}

function ValidationAllCheck(){
  VaildateEmail()
  VaildatePhone()
  ValidatePostCode()
  VaildatePassWord()
  IsSamePassword()
  const isError = Object.values(validationError);
  if(!isError.includes(true)){
    return true;//入力ミスあり
  }else{
    return false;//入力ミスなし
  }
}

function SetUserDataStore() {
  if(!EmptyCheck()){
    if(!ValidationAllCheck()){
      UserData.$patch({
        family_name: family_name.value,
        user_name: user_name.value,
        company_type: company_type.value,
        company_name: company_name.value,
        postcode: postcode.value,
        prefecture: prefecture.value,
        town:town.value,
        city:city.value,
        Street: Street.value,
        building: building.value,
        tel: tel.value,
        phone: phone.value,
        email: email.value,
        password: password1.value,
      })
      GoToNextPage()
    }else{
      alert('入力内容に誤りがあります')
    }
  }else{
    alert('入力内容に空欄があります')
  }
}

const focusInput = () => {
  // refを使用して要素にフォーカスを設定
  myInput.value.focus();
};

const myInput = ref(null);

function ValidatePostCode() {
      // 郵便番号のバリデーションロジックを実装
      if (postcode.value != ''){
        if (/^\d{7}$/.test(postcode.value)) {
          //バリデーションOK
          validationError['postcode'] = true;
          PostCodeToAddress();
        }else{
          //バリデーションNG
          validationError['postcode'] = false;
        }
      }else{
        validationError['postcode'] = null;
      }
    }

function VaildateEmail(){
      if(email.value != ''){
        if(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(email.value)) {
          validationError['email'] = true;
        }else{
          validationError['email'] = false;
        }
      }else{
        validationError['email'] = null;
      }
}

function VaildateTel(){
      if(tel.value != ''){
        if(/^0\d{9,10}$/.test(tel.value)) {
          validationError['tel'] = true;
        }else{
          validationError['tel'] = false;
        }
      }else{
        validationError['tel'] = null;
      }
}
    
function VaildatePhone(){
      if(phone.value != ''){
        if(/^0[789]0[0-9]{4}[0-9]{4}$/.test(phone.value)) {
          validationError['phone'] = true;
        }else{
          validationError['phone'] = false;
        }
      }else{
        validationError['phone'] = null;
      }
}

function VaildatePassWord(){
      if(password1.value != ''){
        if(/^(?=.*[a-zA-Z])(?=.*\d).{6,}$/.test(password1.value)) {
          validationError['password1'] = true;
        }else{
          validationError['password1'] = false;
        }
      }else{
        validationError['password1'] = null;
      }

      if(password2.value != ''){
        if(/^(?=.*[a-zA-Z])(?=.*\d).{6,}$/.test(password2.value)) {
          validationError['password2'] = true;
        }else{
          validationError['password2'] = false;
        }
      }else{
        validationError['password2'] = null;
      }
}

function IsSamePassword(){
  if(password1.value != '' && password2.value != ''){
    if(password1.value == password2.value){
      validationMsg = 'パスワードが一致しました';
      validationError['IsSamePassword'] = true;
    }else{
      validationMsg = 'パスワードが不一致です';
      validationError['IsSamePassword'] = false;
    }
  }else{
    validationMsg = '';
    }
}

function PostCodeToAddress(){
  if(!postcode.value == ''){
    isLoading.value = true;
      const apiUrl = "https://zipcloud.ibsnet.co.jp/api/search?zipcode=" + postcode.value;

    // Axiosを使用してAPIからデータを取得
    axios.get(apiUrl)
      .then((response) => {
        if(response.data.results == null){
          pce.value = '住所が見つかりませんでした';
          validationError['postcode'] = false;
          isLoading.value = false;
        }else{
          pce.value = '';
          PtoA.value = response.data.results
          prefecture.value = PtoA.value[0].address1;
          city.value = PtoA.value[0].address2;
          town.value = PtoA.value[0].address3;
          focusInput();
          isLoading.value = false;
        }
      })
      .catch((error) => {
        isLoading.value = false;
        pce.value = error;
      });
  }else{
    isLoading.value = false;
    pce.value = '郵便番号が空欄です';
  }
}

</script>

<template>
  <div class="bg">
    <div class="centered">
        <div class="centered-element">
    <div class="RegistrationForm">
      <div class="form_item">
        <label>Hayokoiの販売を行う方の<br>氏名を入力してください</label>
        <p>
          <label for="family_name">苗字:</label>
          <input v-model="family_name" size="9" maxlength="5" placeholder="例：ロレンス" type="text" required/>
        </p>
        <p>
        <label for="name">名前:</label>
          <input v-model="user_name" size="9" maxlength="5" placeholder="例：クラフト" type="text" required/>
        </p>
      </div>

      <div class="form_item">
        <label for="company_type">お客様は個人と<br>法人のどちらですか？</label>
          <p>
            <select v-model="company_type" required>
              <option value="" selected>選択してください</option>
              <option value="個人">個人</option>
            </select>
          </p>
      </div>

      <div class="form_item">
        <label for="campany_name">所属の屋号または会社名</label>
        <p>
          <input v-model="company_name" size="25" placeholder="最大50文字まで" maxlength="50" type="text" required/>
        </p>
      </div>

      <div class="form_item">
        <label for="postcode">郵便番号</label>
        <p>
          <input v-model="postcode" placeholder="ハイフン不要" size="15" maxlength="7" @input="ValidatePostCode()"/>
          <span v-if='validationError.postcode != null && validationError.postcode' class="OK">○</span>
          <span v-if='validationError.postcode != null && !validationError.postcode' class="error">×</span>
        </p>

        <div class="loading_space">
            <div class="loader" v-if="isLoading"></div>
        </div>

        <span class='error'>{{pce}}</span>
      </div>

      <div class="form_item">
        <label for="prefecture">都道府県</label>
        <p>
          <select v-model="prefecture" required>
            <option value="" selected>選択してください</option>
            <option v-for="prefecture in Prefectures" :key="prefecture.name" :value="prefecture.name">{{ prefecture.name }}</option>
          </select>
        </p>
      </div>

      <div class="form_item">
        <label for="city">市区町村</label>
        <p>
          <input v-model="city" placeholder="例:熊本市東区" maxlength="25" required/>
        </p>
      </div>

      <div class="form_item">
        <label for="town">町名</label>
        <p>
          <input v-model="town" placeholder="例:東町" maxlength="25" required/>
        </p>
      </div>

      <div class="form_item">
        <label for="Street">番地</label>
        <p>
          <input v-model="Street" placeholder="例:4丁目14-35" ref="myInput" maxlength="25" required/>
        </p>
      </div>

      <div class="form_item">
        <label for="building">建物名など</label>
        <p>
          <input v-model="building" placeholder="無い場合は空欄" maxlength="25"/>
        </p>
      </div>

      <div class="form_item">
        <label for="tel">固定電話番号<br>(ハイフンは不要です)</label>
        <p>
          <input v-model="tel" placeholder="無い場合は空欄" @input="VaildateTel()" maxlength="10"/>
          <span v-if='validationError.tel != null && validationError.tel' class="OK">○</span>
          <span v-if='validationError.tel != null && !validationError.tel' class="error">×</span>
        </p>
      </div>
      
      <div class="form_item">
        <label for="phone">携帯電話番号<br>(ハイフンは不要です)</label>
        <p>
          <input v-model="phone" placeholder="要入力" @input="VaildatePhone" maxlength="11" required/>
          <span v-if='validationError.phone != null && validationError.phone' class="OK">○</span>
          <span v-if='validationError.phone != null && !validationError.phone' class="error">×</span>
        </p>
      </div>

      <div class="form_item">
        <label for="email">メールアドレス</label>
        <p>
          <input v-model="email" size="25" placeholder="要入力" @input="VaildateEmail" maxlength="50" type="text" required/>
          <span v-if='validationError.email != null && validationError.email' class="OK">○</span>
          <span v-if='validationError.email != null && !validationError.email' class="error">×</span>
        </p>
      </div>

      <div class="form_item">
        <label for="password1">パスワード</label>
        <p>
          <input v-model="password1" type="password" placeholder="半角英数6文字以上" @input="VaildatePassWord()" maxlength="50" required/>
          <span v-if='validationError.password1 != null && validationError.password1' class="OK">○</span>
          <span v-if='validationError.password1 != null && !validationError.password1' class="error">×</span>
        </p>
      </div>

      <div class="form_item">
        <label for="password2">パスワード(確認)</label>
        <p>
          <input v-model="password2" type="password" placeholder="半角英数6文字以上" @input="VaildatePassWord()" maxlength="50" required/>
          <span v-if='validationError.password2 != null && validationError.password2' class="OK">○</span>
          <span v-if='validationError.password2 != null && !validationError.password2' class="error">×</span>
        </p>
        <p v-if="validationError.IsSamePassword" class="OK">{{validationMsg}}</p>
        <p v-if="!validationError.IsSamePassword" class="error">{{validationMsg}}</p>
      </div>
  </div>
  <div class="nav">
    <ButtonPositive button_title='次に進む' @click="SetUserDataStore()"/>
  </div>

  <div class="nav">
    <ButtonNegative button_title='戻る' @click="goBack()"/>
  </div>
        </div>
    </div>
</div>
</template>

<style scoped>
@font-face {
	font-family: 'mplus';
	src: url("/src/assets/fonts/rounded-mplus-1c-regular.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.loading_space {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    padding: 10px;
}

/* CSSでローディングサークルのスタイルとアニメーションを定義 */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007B43;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.bg {
    background-color: grey;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* ビューポートの高さに合わせる */
}

.centered-element{
    background-color: white;
}

.RegistrationForm {
  width: 100vw;
  height: 70vh;
  overflow: scroll;
  text-align: center;
  font-family: 'mplus';
}

.form_item {
  border: 1px solid grey;
  padding: 20px;
}

.form_item label{
  margin: 10px;
  font-size: 22px;
}

.form_item select {
  font-size: 20px;
}

.form_item input{
  margin: 10px;
  font-size: 20px;
  line-height: 1.5;
}

.form_item input::-webkit-input-placeholder {
  text-align: center;
}

/* Firefox用のスタイリング */
.form_item input::-moz-placeholder {
  text-align: center;
}

.nav {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.nav button {
  width: 200px;
  height: 50px;
}

p.OK {
  font-size: 20px;
  color: #00ff00;
}

p.error {
  font-size: 20px;
  color: red;
}

span.OK {
  font-size: 20px;
  color: #00ff00;
}

span.error {
  font-size: 20px;
  color: red;
}
</style>