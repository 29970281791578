<template>
        <h1>入力データ確認</h1>
            <p>訂正する場合は「戻る」ボタン<br>から戻ってください</p>
            <div class="Chk_Table">
            <table class="tb01">
                <tbody>
                    <tr>
                        <th>苗字</th>
                        <td>{{UserData.family_name}}</td>
                    </tr>
                    <tr>
                        <th>名前</th>
                            <td>{{UserData.user_name}}</td>
                        </tr>
                        <tr>
                            <th>個人・法人選択</th>
                            <td>{{UserData.company_type}}</td>
                        </tr>
                        <tr>
                            <th>Hayokoiの用途</th>
                            <td v-if="UserData.user_type == 'factory'">自動車部品の発注</td>
                            <td v-if="UserData.user_type == 'seller'">自動車部品の受注</td>
                        </tr>
                        <tr>
                            <th>屋号または会社名</th>
                            <td>{{UserData.company_name}}</td>
                        </tr>
                        <tr>
                            <th>郵便番号</th>
                            <td>{{UserData.postcode}}</td>
                        </tr>
                        <tr>
                            <th>都道府県</th>
                            <td>{{UserData.prefecture}}</td>
                        </tr>
                        <tr>
                            <th>市区町村</th>
                            <td>{{UserData.city}}</td>
                        </tr>
                        <tr>
                            <th>町名</th>
                            <td>{{UserData.town}}</td>
                        </tr>
                        <tr>
                            <th>番地</th>
                            <td>{{UserData.Street}}</td>
                        </tr>
                        <tr v-if="UserData.building != ''">
                            <th>建物名</th>
                            <td>{{UserData.building}}</td>
                        </tr>
                        <tr v-if="UserData.tel != ''">
                            <th>固定電話番号</th>
                            <td>{{UserData.tel}}</td>
                        </tr>
                        <tr>
                            <th>携帯電話番号</th>
                            <td>{{UserData.phone}}</td>
                        </tr>
                        <tr>
                            <th>メールアドレス</th>
                            <td>{{UserData.email}}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
                    <div class="loading_space">
                        <div class="loader" v-if="isLoading"></div>
                    </div>

            <div v-if="!AlreadyRegistered" class="nav">
                <ButtonPositive button_title='この情報で登録' @click="register()"/>
            </div>

            <div v-if="AlreadyRegistered" class="nav">
                <ButtonPositive button_title='料金プランに戻る' @click="GoToNextPage()"/>
            </div>

            <div class="nav">
                <ButtonNegative button_title='戻る' @click="goBack()"/>
            </div>


</template>

<script setup>
import ButtonPositive from '../Common/button_positive.vue';
import ButtonNegative from '../Common/button_negative.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth';
import { getFirestore,doc,setDoc } from 'firebase/firestore';
import { UserDataStore } from '/src/store/UserData';
const router = useRouter();//Routerを定義
const UserData = UserDataStore();
const AlreadyRegistered = ref(UserData.AlreadyRegistered);
const isLoading = ref(false);

const GoToNextPage = () => {
  //登録内容確認画面へ遷移させる関数
  router.push('/Payment');
};

// 前のページに戻る
const goBack = () => {
  router.back();
};

const register = async() => {
    isLoading.value =true;//ローディングサークルON
      const auth = getAuth();
      const db = getFirestore(); // Firestoreの初期化
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, UserData.email, UserData.password);
        const user = userCredential.user;
        
        const docRef = doc(db, "users", user.uid);
        //データ定義
        const datatosend = {
            email: user.email,
            family_name: UserData.family_name,
            name: UserData.user_name,
            type:UserData.user_type,
            company_name: UserData.company_name,
            postcode: UserData.postcode,
            prefecture: UserData.prefecture,
            city: UserData.city,
            town: UserData.town,
            street: UserData.Street,
            building: UserData.building,
            tel: UserData.tel,
            phone: UserData.phone,
            payment_status: false,
            PIC: localStorage.getItem('uid'),
            distributor:false,
            //SMS配信追加
            Reminder:{'inspection':{'is_enabled':true,'len_of_days':30}}
        };

        // Firestoreにデータを保存
        await setDoc(docRef,datatosend);

        //メール認証送信
        sendEmailVerification(auth.currentUser);

        //支払いリンク送信
        

        isLoading.value = false;//ローディングサークルOFF
        UserData.$patch({AlreadyRegistered : true})

        GoToNextPage();
      } catch (error) {
        isLoading.value = false;
        const errorCode = error.code;
        if(errorCode == 'auth/email-already-in-use'){
            alert('メールアドレスがすでに登録されています');
        }
      }
}

</script>


<style>

@font-face {
	font-family: 'mplus';
	src: url("/src/assets/fonts/rounded-mplus-1c-regular.ttf");
}

@font-face {
	font-family: 'Kikai';
	src: url("/src/assets/fonts/KikaiChokokuJIS-Md.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.loading_space {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    padding: 10px;
}

/* CSSでローディングサークルのスタイルとアニメーションを定義 */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007B43;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

table{
  border-collapse: collapse;
  width: 100%;
}

h1 {
    font-family: 'Kikai';
    text-align: center;
    padding: 10px;
    background-color: #007B43;
    color: white;
}

p {
    font-family: 'mplus';
    text-align: center;
    padding: 10px;
}

.tb01 th{
  padding: 10px;
  border: solid 1px #ccc;
  text-align:center;
  box-sizing:border-box;
  font-family: 'Kikai';
  font-size: 22px;
}

.tb01 td{
  padding: 20px;
  border: solid 1px #ccc;
  text-align:center;
  box-sizing:border-box;
  font-family: 'mplus';
  font-size: 20px;
}


.tb01 th {
  background: #007B43;
  color: #fff;
  
}


@media screen and (min-width: 640px) {
  .tb01 {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 640px) {
  .Chk_Table {
    height: 50vh;
    overflow: scroll;
    border-bottom: 1px solid grey;
  }

  .tb01 {
    width: 100%;
  }
  table.tb01 th,
  table.tb01 td {
    display: block;
    width: 100%;
    border-bottom:none;
  }
  .tb01 tr:last-child{
    border-bottom: solid 1px #ccc;
  }
}
.nav {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>