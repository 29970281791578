<template>
  <button class="button_positive" @click="handleClick"><span>{{button_title}}</span></button>
</template>

<script>
export default {
    props:{
        button_title: String,
    },

    methods: {
        handleClick() {},
    },
};
</script>

<style>

@font-face {
	font-family: 'mplus';
	src: url("/src/assets/fonts/rounded-mplus-1c-regular.ttf");
}

span {
    font-family: 'mplus';
    font-weight: 600;
    font-size: 16px;
}

.button_positive {
  /* ボタンのスタイリングを追加 */
  background-color: #007B43;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.button_positive:hover {
  background-color: #009954;
}
</style>