<template>
<div class="bg2">
            <div class="Header">
                    <h1>Hayokoi</h1>
                    <p>明日を走らせるあなたのアプリ</p>
            </div>
            <div class="sample">
              <h2 class="sample_msg">Hayokoi 車検証QR活用アプリ</h2>
              <p class="sample_msg">車検証のQRコードを活用して<br>日々の業務を効率化してみませんか？</p>
              <img class="sample_img" :src="require('/src/assets/img/sample.png')">
                  <p><a href="https://apps.apple.com/jp/app/hayokoi/id6479526401"><img class="app_btn" :src="require('/src/assets/img/App_Store_Badge.svg')"></a></p>
                  <p><a href="https://play.google.com/store/apps/details?id=llc.wakatec.hayokoi&pcampaignid=web_share"><img class="app_btn" :src="require('/src/assets/img/google-play-badge.png')"></a></p>
            </div>
        </div>
            <div class="content">
              <section>
                <div class="article1" id="about">
                <h2>Hayokoiにできること</h2>
                  <h3>車検証QRを読み取って記録</h3>
                    <img :src="require('/src/assets/img/QRtoSave.jpg')">
                    <p>
                      Hayokoiは車検証のQRコードを読み取って、そのデータをクラウド上に記録させることができます。
                      <br>もちろん、履歴からこのデータを後で活用することができます。
                    </p>
                  <h3>車検証データから発注や見積を送れます</h3>
                  <img :src="require('/src/assets/img/QRandParts.jpg')">
                    <p>
                      読み取った車検証データに、HAYOKOIが提供する部品リストから<br>
                      必要な部品を選択して発注や見積を送ることもできます。
                    </p>
                  <h3>お車のデータと各種データを紐付けることができます</h3>
                  <img :src="require('/src/assets/img/QRandCustomer.jpg')">
                    <p>
                      読み取った車検証データに、お客様の端末の電話帳データをはじめ<br>
                      走行距離、車検有効期限などを紐付けることができます。
                    </p>
                  <h3>お客様に車検時期をお知らせするSMSを自動送信</h3>
                  <img :src="require('/src/assets/img/QRandSMS.jpg')">
                    <p>
                      Hayokoiは車検時期をリマインドするSMSを自動配信することができます。<br>
                      ※当機能のご利用にはお車のデータと連絡先の紐付けが必要です。
                    </p>
                  <h3>Hayokoiはもっと便利になります</h3>
                  <p>
                    Hayokoiは自動車整備に関わる人のためのアプリとして開発されましたが<br>
                    その枠を越えて、自動車に関わる全ての人のお役に立てるアプリとなることを目指しています。<br>
                    Hayokoiを自社サービスと連携してみたい、Hayokoiからの注文や見積を受けてみたいなどなど
                    私達と一緒に新しい市場を作ってみませんか？<br>
                    随時、協力企業様を募集しております。<br>
                    お問い合わせはフォームより承っております。
                  </p>
                    </div>
                  </section>

              <section>
                <div class="story">
                <h2>Hayokoiは町工場生まれ</h2>
                <img :src="require('/src/assets/img/kouba.jpg')">
                <p>
                  Hayokoiは小さな町の小さな自動車整備工場で生まれました。
                  そこは小さな工場でしたが、いつも多くのクルマが修理や整備を待っているような町工場です。
                  日々、時間に追われる中でも安全で信頼される仕事を提供してきました。
                  そんな忙しい日々の中、作業が電話対応で止まったり、必要な部品の注文や見積を出す際には
                  電話で情報を読み上げていることに気が付きました。
                  <br>果たして本当にこれでいいのか？
                  <br>もっと効率よくできないのか？
                  <br>解決するには何を作ればいいのか？
                  <br>そして、私たちに作れるのか？
                  <br>こんな疑問から全てが始まり、Hayokoiが生まれました。
                </p>
                </div>
              </section>
              
              <section>
                <div class="payment">
                <h2>料金プラン</h2>
                <div class="plan_table">
                <ul class="plan">
                  <li><p>より多くのメカニックに使っていただきたいので<br>このお値段にしました。</p></li>
                  <li><p id="price1">月額6,600円(税込)</p></li>
                  <li>車検証QRから見積発注</li>
                  <li>車検証QRと写真で見積発注</li>
                  <li>顧客管理機能</li>
                  <li>車検時期お知らせ(100通/月まで)</li>
                  <li>従業員設定機能(代表者+3名まで)</li>
                </ul>
                </div>
                </div>
              </section>

              <section>
                <div class="flow">
                  <h2>Hayokoiご利用までの流れ</h2>
                    <h3>1.会員登録</h3>
                      <ul class="flow_header">
                        <li>
                          <img :src="require('/src/assets/img/register.png')">
                        </li>
                        <li>
                          <p>
                            Hayokoiをご利用になるには会員登録が必要です。<br>
                            車検証データと部品データを合わせて発注や見積を行う場合に<br>
                            発注元や見積依頼を行っているユーザーの情報が必要となります。
                          </p>
                        </li>
                      </ul>
                  
                    <h3>2.料金のお支払い</h3>
                      <ul class="flow_header">
                        <li>
                          <img :src="require('/src/assets/img/payment.png')">
                        </li>
                        <li>
                          <p>
                            Hayokoiのご利用には料金が掛かります。<br>
                            決済にはクレジットカードが必要です。
                          </p>
                        </li>
                      </ul>

                  <h3>3.アカウント発行</h3>
                    <ul class="flow_header">
                      <li>
                        <img :src="require('/src/assets/img/check.png')">
                      </li>
                      <li>
                        <p>
                          会員登録と料金のお支払いが完了しましたら、<br>Hayokoiのアカウントを発行します。
                        </p>
                      </li>
                    </ul>

                  <h3>4.アプリからログイン</h3>
                    <ul class="flow_header">
                      <li>
                        <img :src="require('/src/assets/img/login.png')">
                      </li>
                      <li>
                        <p>
                          アカウントが発行されたら、アプリからログインすることで<br>ご利用を開始できます。
                        </p>
                      </li>
                    </ul>
                  
                </div>
              </section>

              <section>
                <div class="question" id="QandA">
                  <h2>よくあるご質問(FAQ)</h2>
                  <h3>新しい車検証(電子車検証)しか読み取れないの？</h3>
                  <p>
                    新しい車検証(電子車検証)に加えてこれまでの普通車車検証、<br>
                    軽自動車車検証に加えて登録識別情報等通知書(一時抹消)の<br>
                    書類も読み取ることが出来ます。
                  </p>
                  <h3>車検証に記載された個人情報の扱いは？</h3>
                  <p>
                    新しい車検証(電子車検証)のQRコードには個人情報(所有者氏名、住所等)が記録されていません。
                    またHayokoiは自動車部品の発注や見積に必要な情報(車体番号、型式指定番号、類別区分番号)などを活用します。
                    よって個人情報(所有者氏名、住所等)を記録することはありません。<br>
                    詳しくはプライバシポリシ―をご覧ください。
                  </p>
                  <h3>引落日はいつになるの？</h3>
                  <p>
                    会員登録を行い、アカウントが発行された日が引落日となります。<br>
                    例えば、3月3日にアカウントが発行された場合は<br>
                    引落日は翌月の4月3日となります。<br>
                  </p>
                  <h3  id="contuct">Hayokoiに関するお問い合わせは以下のフォームをご利用ください</h3>
                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdaiPfiMjnU9Kod59sllHxYnwKXvDSoV6UUEv3E7mMr1ISmaw/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
                </div>
              </section>
              
            </div>
            <div class="footer">
              <div class="sample_bottom">
                <p class="sample_bottom_msg">
                  車検証のQRコードを活用して<br>
                  日々の業務を効率化してみませんか？<br>
                </p>
                <img class="sample_img" :src="require('/src/assets/img/sample.png')">
                  <p><a href="https://apps.apple.com/jp/app/hayokoi/id6479526401"><img class="app_btn" :src="require('/src/assets/img/App_Store_Badge.svg')"></a></p>
                  <p><a href="https://play.google.com/store/apps/details?id=llc.wakatec.hayokoi&pcampaignid=web_share"><img class="app_btn" :src="require('/src/assets/img/google-play-badge.png')"></a></p>
              </div>

              <div class="footer_links">
                <ul>
                  <li><a href="">ホーム</a></li>
                  <li><router-link to="/Manual">取扱説明書</router-link></li>
                  <li><a href="https://wakatec.llc">会社情報</a></li>
                  <li><router-link to="/Tokutei">特定商取引法に基づく表記</router-link></li>
                  <li><router-link to="/Kiyaku">利用規約</router-link></li>
                  <li><router-link to="/PrivacyPolicy">プライバシーポリシー</router-link></li>
                  <li><router-link to="/Login">マイページ</router-link></li>
                </ul>
              </div>

              <p class="copyright">© 2024 WakaTec.LLC Hayokoi Created by Mutsuki Wakai</p>

            </div>
</template>



<script setup>
</script>

<style>

@font-face {
	font-family: 'mplus';
	src: url("/src/assets/fonts/rounded-mplus-1c-regular.ttf");
}

@font-face {
	font-family: 'Kikai';
	src: url("//home/wakai/development/Website/hayokoi_register/src/assets/fonts/KikaiChokokuJIS-Md.ttf");
}

* {
    margin: 0;
    padding: 0;
  }

/*PCのデザイン定義ここから*/
@media screen and (min-width: 800px) {

.bg2 {
  width: 100%;
  height: 1080px;
  background: url('@/assets/img/top.webp') center center / cover no-repeat;
}

.Header{
  background-color: #007B43;
}

.Header h1 {
  color: white;
  font-family: 'Kikai';
  text-align: center;
  font-size: 60px;
}

.Header p{
  color: white;
  text-align: center;
  font-family: 'mplus', sans-serif;
  font-size: 30px;
}

.sample {
  width: 35%;
  height: fit-content;
  text-align: center;
}

h2.sample_msg {
  color: black;
  font-family: 'Kikai';
  padding: 15px;
  font-size: 30px;
}

p.sample_msg {
  padding: 10px;
  color: black;
  font-family: 'mplus', sans-serif;
  font-size: 20px;
}

.sample_img {
  width: 30%;
  padding: 5px;
}

.app_btn {
  width: 170px;
  height: fit-content;
  margin: 5px;
}

.article1 {
  width: 100%;
  text-align: center;
}

.article1 h2 {
  background-color: #007B43;
  color: white;
  font-family: 'mplus';
  font-size: 40px;
  margin-top: 20px;
  padding: 10px;
}

.article1 h3 {
  font-family: 'mplus';
  font-size: 30px;
  margin-top: 20px;
  padding: 10px;
}

.article1 img {
  width: fit-content;
  height: fit-content;
  padding: 10px;
  border: black solid 1px;
}

.article1 p {
  margin: 0 auto;
  text-align: left;
  width: 35%;
  font-family: 'mplus';
  font-size: 20px;
  padding: 10px;
}

.story {
  width: 100%;
  text-align: center;
}

.story h2 {
  background-color: #007B43;
  color: white;
  font-family: 'mplus';
  font-size: 40px;
  margin-top: 20px;
  padding: 10px;
}

.story h3 {
  font-family: 'mplus';
  font-size: 30px;
  margin-top: 20px;
  padding: 10px;
}

.story img {
  width: 480px;
  height: 360px;
  padding: 20px;
}

.story p {
  margin: 0 auto;
  text-align: left;
  width: 35%;
  font-family: 'mplus';
  font-size: 20px;
  padding: 10px;
}

.payment {
  width: 100%;
  text-align: center;
}

.payment h2 {
  background-color: #007B43;
  color: white;
  font-family: 'mplus';
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}

.payment h3 {
  background-color: #007B43;
  border-radius: 10px;
  color: white;
  font-family: 'mplus';
  font-size: 30px;
}

.payment p {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  font-family: 'mplus';
  font-size: 20px;
  padding: 10px;
}

.plan_table {
  display: flex;
}

.plan {
  margin: 0 auto;
  width: 30%;
  padding: 10px;
  border: 1px solid black;
}

.plan h3 {
  text-align: center;
}

.plan li{
  width: 70%;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-family: 'mplus';
  list-style: none;
}

#price1{
  font-size: 30px;
}

#price2{
  font-size: 30px;
}

#price3{
  font-size: 30px;
}

.flow {
  width: 100%;
  text-align: center;
}

.flow h2 {
  background-color: #007B43;
  color: white;
  font-family: 'mplus';
  font-size: 40px;
  margin-top: 20px;
  padding: 10px;
}

.flow h3 {
  font-family: 'Kikai';
  font-size: 30px;
  margin-top: 20px;
  padding: 10px;
}

.flow p {
  margin: 0 auto;
  text-align: left;
  font-family: 'mplus';
  font-size: 20px;
  padding: 10px;
}

.flow img {
  width: 80px;
  height: 80px;
  padding: 10px;
  border: #007B43 solid 2px;
  border-radius: 10px;
}

ul.flow_header {
  display: -webkit-flex;
  display: flex;
  width: 50%;
  margin-left: 31%;
  margin-right: auto;
}

.flow li {
  list-style: none;
  width: fit-content;
  padding: 20px;
}

.question {
  width: 100%;
  text-align: center;
}

.question h2 {
  background-color: #007B43;
  color: white;
  font-family: 'mplus';
  font-size: 40px;
  margin-top: 20px;
  padding: 10px;
}

.question h3 {
  font-family: 'mplus';
  font-size: 30px;
  margin-top: 20px;
  padding: 10px;
}

.question p {
  margin: 0 auto;
  text-align: center;
  width: 35%;
  font-family: 'mplus';
  font-size: 20px;
  padding: 10px;
}

.question iframe {
  width: 640px;
  height: 1200px;
}

.footer {
  width: 100%;
  background-color: #007B43;
}

.sample_bottom {
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  text-align: center;
}

p.sample_bottom_msg {
  padding: 10px;
  color: white;
  font-family: 'mplus', sans-serif;
  font-size: 20px;
}

.copyright {
  padding: 20px;
  color: white;
  font-family: 'Kikai';
  font-size: 16px;
  text-align: center;
}

.footer img {
  width: 15%;
}

.footer_links {
  text-align: center;
}

.footer_links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* リスト内のアイテム（リンク）を横並びに配置 */
.footer_links ul li {
    display: inline;
    margin-right: 10px; /* リンク間の余白を調整 */
}

/* リンクのスタイル（オプション） */
.footer_links ul li a {
    text-decoration: underline;
    font-family: 'Kikai';
    font-size: 16px;
    color: white; /* リンクのテキストカラー */
}


}

/*スマートフォンのデザイン定義ここから*/
@media screen and (max-width: 800px) {

/*スマートフォン縦画面のデザイン定義ここから*/
@media screen and (orientation: portrait) {

  .bg2 {
    width: 100%;
    height: fit-content;
  }
  
  .Header{
    background-color: #007B43;
  }
  
  .Header h1 {
    color: white;
    font-family: 'Kikai';
    text-align: center;
    font-size: 3pc;
  }
  
  .Header p{
    color: white;
    text-align: center;
    font-family: 'mplus', sans-serif;
    font-size: 20px;
  }
  
  .sample {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    text-align: center;
  }
  
  h2.sample_msg {
    color: black;
    font-family: 'Kikai';
    padding: 10px;
    font-size: 20px;
  }
  
  p.sample_msg {
    padding: 5px;
    color: black;
    font-family: 'mplus', sans-serif;
    font-size: 18px;
  }
  
  .sample_img {
    width: 35%;
    padding: 5px;
  }
  
  .app_btn {
    width: 30%;
    height: 20%;
  }
  
  .article1 {
    width: 100%;
    text-align: center;
  }
  
  .article1 h2 {
    background-color: #007B43;
    color: white;
    font-family: 'mplus';
    font-size: 30px;
    padding: 10px;
  }
  
  .article1 h3 {
    font-family: 'mplus';
    font-size: 20px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .article1 img {
    width: 90%;
    padding: 10px;
    border: black solid 1px;
  }
  
  .article1 p {
    margin: 0 auto;
    text-align: left;
    width: 90%;
    font-family: 'mplus';
    font-size: 18px;
  }
  
  .story {
    width: 100%;
    text-align: center;
  }
  
  .story h2 {
    background-color: #007B43;
    color: white;
    font-family: 'mplus';
    font-size: 25px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .story h3 {
    font-family: 'mplus';
    font-size: 20px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .story p {
    margin: 0 auto;
    text-align: left;
    width: 90%;
    font-family: 'mplus';
    font-size: 18px;
  }

  .story img {
    width: 95%;
    height: 30%;
  }
  
  .payment {
    width: 100%;
    text-align: center;
  }
  
  .payment h2 {
    background-color: #007B43;
    color: white;
    font-family: 'mplus';
    font-size: 30px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .payment h3 {
    color: white;
    font-family: 'mplus';
    background-color: #007B43;
    border-radius: 10px;
    font-size: 20px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .payment p {
    margin: 0 auto;
    text-align: center;
    width: 95%;
    font-family: 'mplus';
    font-size: 18px;
  }

  .plan {
  margin: 0 auto;
  width: 95%;
  padding: 5px;
  border: 1px solid black;
}

.plan h3 {
  text-align: center;
}

.plan li{
  width: 70%;
  margin: 0 auto;
  text-align: center;
  font-size: 2vw;
  font-family: 'mplus';
  list-style: none;
}

#price1{
  font-size: 4vw;
}

#price2{
  font-size: 4vw;
}

#price3{
  font-size: 4vw;
}

  .flow {
    width: 100%;
    text-align: center;
  }
  
  .flow h2 {
    background-color: #007B43;
    color: white;
    font-family: 'mplus';
    font-size: 30px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .flow h3 {
    font-family: 'Kikai';
    font-size: 20px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .flow p {
    margin: 0 auto;
    text-align: left;
    font-family: 'mplus';
    font-size: 18px;
    padding: 5px;
  }
  
  .flow img {
    width: 40px;
    height: 40px;
    padding: 5px;
    border: #007B43 solid 2px;
    border-radius: 10px;
  }
  
  ul.flow_header {
    display: -webkit-flex;
    display: flex;
    width: 80%;
    margin-left: 15%;
    margin-right: auto;
  }
  
  .flow li {
    list-style: none;
    width: fit-content;
    padding: 10px;
  }
  
  .question {
    width: 100%;
    text-align: center;
  }
  
  .question h2 {
    background-color: #007B43;
    color: white;
    font-family: 'mplus';
    font-size: 30px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .question h3 {
    font-family: 'mplus';
    font-size: 20px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .question p {
    margin: 0 auto;
    text-align: left;
    width: 90%;
    font-family: 'mplus';
    font-size: 18px;
    padding: 10px;
  }
  
  .question iframe {
    width: 100%;
    height: 1300px;
  }
  
  .footer {
    width: 100%;
    background-color: #007B43;
  }
  
  .sample_bottom {
    margin: 0 auto;
    width: fit-content;
    height: fit-content;
    text-align: center;
  }
  
  p.sample_bottom_msg {
    padding: 10px;
    color: white;
    font-family: 'mplus', sans-serif;
    font-size: 20px;
  }
  
  .copyright {
    padding: 10px;
    color: white;
    font-family: 'Kikai';
    font-size: 12px;
    text-align: center;
  }

  .footer_links {
  text-align: center;
}

.footer_links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* リスト内のアイテム（リンク）を横並びに配置 */
.footer_links ul li {
    display: inline;
    margin-right: 10px; /* リンク間の余白を調整 */
}

/* リンクのスタイル（オプション） */
.footer_links ul li a {
    text-decoration: underline;
    font-family: 'Kikai';
    font-size: 14px;
    color: white; /* リンクのテキストカラー */
}
}

/*スマートフォン横画面のデザイン定義ここから*/
@media screen and (orientation: landscape) {
  body{
    -webkit-text-size-adjust: 100%;	
  }

  .bg2 {
    width: 100%;
    height: fit-content;
  }
  
  .Header{
    background-color: #007B43;
  }
  
  .Header h1 {
    color: white;
    font-family: 'Kikai';
    text-align: center;
    font-size: 3pc;
  }
  
  .Header p{
    color: white;
    text-align: center;
    font-family: 'mplus', sans-serif;
    font-size: 20px;
  }
  
  .sample {
    width: fit-content;
    height: fit-content;
    text-align: center;
    margin: 0 auto;
  }
  
  h2.sample_msg {
    color: black;
    font-family: 'Kikai';
    padding: 15px;
    font-size: 20px;
  }
  
  p.sample_msg {
    padding: 10px;
    color: black;
    font-family: 'mplus', sans-serif;
    font-size: 18px;
  }
  
  .sample_img {
    width: 30%;
    padding: 10px;
  }
  
  .app_btn {
    width: 30%;
    height: 20%;
    margin: 5px;
  }
  
  .article1 {
    width: 100%;
    text-align: center;
  }
  
  .article1 h2 {
    background-color: #007B43;
    color: white;
    font-family: 'mplus';
    font-size: 30px;
    padding: 10px;
  }
  
  .article1 h3 {
    font-family: 'mplus';
    font-size: 20px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .article1 img {
    width: 50%;
    padding: 10px;
    border: black solid 1px;
  }
  
  .article1 p {
    margin: 0 auto;
    text-align: left;
    width: 50%;
    font-family: 'mplus';
    font-size: 18px;
  }
  
  .story {
    width: 100%;
    text-align: center;
  }
  
  .story h2 {
    background-color: #007B43;
    color: white;
    font-family: 'mplus';
    font-size: 30px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .story h3 {
    font-family: 'mplus';
    font-size: 20px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .story img {
    width: 50%;
  }
  
  .story p {
    margin: 0 auto;
    text-align: left;
    width: 50%;
    font-family: 'mplus';
    font-size: 18px;
  }
    .payment {
    width: 100%;
    text-align: center;
  }
  
  .payment h2 {
    background-color: #007B43;
    color: white;
    font-family: 'mplus';
    font-size: 30px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .payment h3 {
    color: white;
    font-family: 'mplus';
    background-color: #007B43;
    border-radius: 10px;
    font-size: 20px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .payment p {
    margin: 0 auto;
    text-align: center;
    width: 95%;
    font-family: 'mplus';
    font-size: 18px;
  }

  .plan {
  margin: 0 auto;
  width: 95%;
  padding: 5px;
  border: 1px solid black;
}

.plan h3 {
  text-align: center;
}

.plan li{
  width: 70%;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-family: 'mplus';
  list-style: none;
}

#price1{
  font-size: 20px;
}

#price2{
  font-size: 20px;
}

#price3{
  font-size: 20px;
}
  
  .flow {
    width: 100%;
    text-align: center;
  }
  
  .flow h2 {
    background-color: #007B43;
    color: white;
    font-family: 'mplus';
    font-size: 30px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .flow h3 {
    font-family: 'Kikai';
    font-size: 20px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .flow p {
    margin: 0 auto;
    text-align: left;
    font-family: 'mplus';
    font-size: 18px;
    padding: 5px;
  }
  
  .flow img {
    width: 40px;
    height: 40px;
    padding: 5px;
    border: #007B43 solid 2px;
    border-radius: 10px;
  }
  
  ul.flow_header {
    display: -webkit-flex;
    display: flex;
    width: 80%;
    margin-left: 20%;
    margin-right: auto;
  }
  
  .flow li {
    list-style: none;
    width: fit-content;
    padding: 10px;
  }
  
  .question {
    width: 100%;
    text-align: center;
  }
  
  .question h2 {
    background-color: #007B43;
    color: white;
    font-family: 'mplus';
    font-size: 30px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .question h3 {
    font-family: 'mplus';
    font-size: 20px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .question p {
    margin: 0 auto;
    text-align: center;
    width: 90%;
    font-family: 'mplus';
    font-size: 18px;
    padding: 10px;
  }
  
  .question iframe {
    width: 100%;
    height: 1300px;
  }
  
  .footer {
    width: 100%;
    background-color: #007B43;
  }
  
  .sample_bottom {
    margin: 0 auto;
    width: 40%;
    height: fit-content;
    text-align: center;
  }
  
  p.sample_bottom_msg {
    padding: 10px;
    color: white;
    font-family: 'mplus', sans-serif;
    font-size: 20px;
  }
  
  .copyright {
    padding: 10px;
    color: white;
    font-family: 'Kikai';
    font-size: 12px;
    text-align: center;
  }
  
    .footer_links {
  text-align: center;
}

.footer_links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* リスト内のアイテム（リンク）を横並びに配置 */
.footer_links ul li {
    display: inline;
    margin-right: 10px; /* リンク間の余白を調整 */
}

/* リンクのスタイル（オプション） */
.footer_links ul li a {
    text-decoration: underline;
    font-family: 'Kikai';
    font-size: 14px;
    color: white; /* リンクのテキストカラー */
}
}
}
</style>