<template>
<div class="bg">
    <div class="centered">
        <div class="centered-element">
            <div class="Terms">
                <h1>Hayokoi取扱説明書</h1>
                <iframe src="https://drive.google.com/file/d/1ilQTli7HPyIl_ZFj0Fl7ViK5LejNSaJw/preview" frameborder="0"></iframe>
            </div>

            <div class="nav">
                    <ButtonPositive button_title='戻る' @click="goBack()"/>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import ButtonPositive from '../Common/button_positive.vue';
// Vue Routerをインポート
import { useRouter } from 'vue-router';


// useRouter()を使用してrouterオブジェクトを取得
const router = useRouter();

// 前のページに戻る
const goBack = () => {
  router.push('/');
};


</script>



<style scoped>


@font-face {
	font-family: 'mplus';
	src: url("/src/assets/fonts/rounded-mplus-1c-regular.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.bg {
    background-color: grey;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* ビューポートの高さに合わせる */
}

.centered-element{
    background-color: white;
}

.Terms {
  width: 90vw;
  height: 80vh;
  overflow-y: scroll;
  border: black 2px solid;
  padding: 10px;
}

.Terms h1 {
  text-align: center;
  font-family: 'mplus';
}

.Terms p {
  margin: 10px;
}

.Terms li {
  list-style: none;
  margin: 10px;
}

.Terms iframe {
    width: 90vw;
    height: 75vh;
}

.header {
    background-color: #007B43;
    padding: 20px;
}

.header h1{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 30px;
}

.header p{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 18px;
}

.nav {
  width: fit-content;
  height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.nav button {
  width: 200px;
  height: 50px;
}
</style>