<template>
<div class="bg">
    <div class="centered">
        <div class="centered-element">
            <div class="header">
                <h1>HAYOKOI<br>アカウント作成</h1>
                <p>HAYOKOIのご利用には会員情報登録と<br>料金のお支払いが必要です</p>
            </div>

            <div class="nav">
                <router-link to="/TermsOfUse">
                    <ButtonPositive button_title='登録をはじめる'/>
                </router-link>
            </div>

            <div class="nav">
                <router-link to="/DashBoard">
                    <ButtonNegative button_title='戻る'/>
                </router-link>
            </div>
        </div>
    </div>
</div>
</template>



<script setup>
import ButtonPositive from '../Common/button_positive.vue';
import ButtonNegative from '../Common/button_negative.vue';
import { UserDataStore } from '/src/store/UserData';
import { onBeforeMount } from 'vue';
const UserData = UserDataStore();

onBeforeMount(() => {
    if(UserData.AlreadyRegistered){
        UserData.$reset();
    }//ここでStoreに格納していた登録データを初期化する
});

</script>

<style>

@font-face {
	font-family: 'mplus';
	src: url("/src/assets/fonts/rounded-mplus-1c-regular.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.bg {
    background-color: grey;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* ビューポートの高さに合わせる */
}

.centered-element{
    background-color: white;
}

.header {
    background-color: #007B43;
    padding: 20px;
}

.header h1{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 30px;
}

.header p{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 18px;
}

.nav {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
}
</style>