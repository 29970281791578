<template>
    <div class="loading_space">
        <div class="loader"></div>
    </div>
</template>

<script>
</script>

<style>
.loading_space {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    padding: 10px;
}

/* CSSでローディングサークルのスタイルとアニメーションを定義 */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007B43;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>