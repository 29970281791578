<template>
<div class="bg">
    <div class="centered">
        <div class="centered-element">
            <div class="header">
                <h1>HAYOKOIの料金プラン</h1>
                <p>3つの料金プランからお選び頂けます</p>
            </div>
            <div class="for_individual" v-if="UserData.company_type == '個人'">
            <table>
              <tr>
                <th>ライト</th>
              </tr>
              <tr>
                <td>
                  <vue-qrcode :value="PaymentURL1" :options="{width:100}"></vue-qrcode>
                </td>
              </tr>
              <tr>
                <td class="price">¥6,600</td>
              </tr>
            </table>
            </div>

            <div class="nav">
              <ButtonNegative button_title='戻る' @click="goBack()"/>
            </div>

            <div class="nav">
              <ButtonPositive button_title='完了' @click="GoToNextPage()"/>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import ButtonPositive from '../Common/button_positive.vue';
import ButtonNegative from '../Common/button_negative.vue';
import { useRouter } from 'vue-router';
import { UserDataStore } from '/src/store/UserData';
import { ref } from 'vue';
const UserData = UserDataStore();
const encodedEmail = encodeURIComponent(UserData.email);
const PaymentURL1 = ref("https://buy.stripe.com/eVa7uz2cR59c9eo5kq?prefilled_email=" + encodedEmail);
//const PaymentURL2 = ref("https://buy.stripe.com/00g9CHg3HatwgGQ4gn?prefilled_email=" + encodedEmail);
//const PaymentURL3 = ref("https://buy.stripe.com/8wM02718N1X062c4go?prefilled_email=" + encodedEmail);

const router = useRouter();//Routerを定義

const GoToNextPage = () => {
  //登録完了画面へ遷移させる関数
  router.push('/RegisterCompleted');
};

// 前のページに戻る
const goBack = () => {
  router.back();
};

</script>

<style>

@font-face {
	font-family: 'mplus';
	src: url("/src/assets/fonts/rounded-mplus-1c-regular.ttf");
}

* {
    margin: 0;
    padding: 0;
}

.bg {
    background-color: grey;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* ビューポートの高さに合わせる */
}

.centered-element{
    background-color: white;
}

.header {
    background-color: #007B43;
    padding: 20px;
}

.header h1{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 30px;
}

.header p{
    font-family: 'mplus';
    color: white;
    text-align: center;
    font-size: 18px;
}

table{
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 50%;
}

table tr:last-child{
  border-bottom:solid 1px #ddd;
}

table th{
  text-align: center;
  padding: 10px 0;
  border-right:solid 1px #ddd;
  border-left:solid 1px #ddd;
  width: 10%;
  font-family: 'mplus';
  font-size: 22px;
}

table td{
  text-align: center;
  padding: 7px 0;
  border-right:solid 1px #ddd;
  border-left:solid 1px #ddd;
  width: 10%;
  font-family: 'mplus';
  font-size: 18px;
}

td.price {
  font-size: 30px;
  font-weight: 900;
}


.nav {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
}
</style>